var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.environment)?_c('div',{staticClass:"pa-0"},[_c('v-row',{staticClass:"pb-2",attrs:{"align":"start","no-gutters":""}},[_c('span',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(_vm.envName)+" ")]),_c('v-spacer'),(_vm.isGameServer)?_c('v-btn',{staticClass:"mr-6",attrs:{"to":{
          name: 'environment',
          params: { envName: _vm.environment.name, id: _vm.environment.id },
        },"color":"primary darken-2","disabled":_vm.loading,"large":"","elevation":"10"}},[_vm._v(" "+_vm._s(_vm.labels.gs)+" ")]):_vm._e()],1),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-btn-toggle',{attrs:{"color":"primary","mandatory":"","multiple":"","rounded":""},model:{value:(_vm.selectedNamespaces),callback:function ($$v) {_vm.selectedNamespaces=$$v},expression:"selectedNamespaces"}},_vm._l((_vm.namespaces),function(readOnly,namespace){return _c('v-btn',{key:namespace,attrs:{"value":namespace,"x-small":""}},[_vm._v(" "+_vm._s(namespace)+" ")])}),1),_c('v-spacer'),(_vm.canViewSystem)?_c('v-checkbox',{attrs:{"color":"primary","hide-details":"auto","label":_vm.labels.showSystem},model:{value:(_vm.system),callback:function ($$v) {_vm.system=$$v},expression:"system"}}):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[(!_vm.firstLoad)?_c('v-data-table',{staticClass:"flex",attrs:{"headers":_vm.headers,"item-class":function (item) { return _vm.highlightItem(item); },"items":_vm.visibleWorkloads,"items-per-page":_vm.itemsPerPage,"multi-sort":"","search":_vm.search,"sort-by":_vm.sort,"sort-desc":_vm.desc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"hide-details":"","label":_vm.labels.search,"prepend-icon":'$search',"single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.metadata.namespace",fn:function(ref){
        var value = ref.value;
return [_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v(_vm._s(value))])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.getUnavailableReplicas(item) ? 'warning' : 'success',"dark":""}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.replicas",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadyReplicas(item))+" / "+_vm._s(_vm.getReplicas(item))+" ")]}},{key:"item.version",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"monospace"},[_vm._v(" "+_vm._s(_vm.getLast(item))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.canRestart(item),"text":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.restart(item)}}},[_vm._v(" "+_vm._s(_vm.labels.restart)+" ")]),_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.showInfo(item)}}},[_vm._v(" "+_vm._s(_vm.labels.info)+" ")])]}}],null,false,3053403345)}):(_vm.loading)?_c('v-skeleton-loader',{staticClass:"flex",attrs:{"loading":"","type":"table"}}):_vm._e()],1)],1):(!_vm.loading)?_c('environment-warning'):_vm._e(),(_vm.showInfoDialog)?_c('pod-details',{staticClass:"dialog-1",attrs:{"workload":_vm.currentWorkload,"show":_vm.showInfoDialog},on:{"close":function($event){_vm.showInfoDialog = false},"showDialog":_vm.showContainerDialog}}):_vm._e(),(_vm.showContainerInfoDialog)?_c('details-dialog',{staticClass:"dialog-2",attrs:{"show":_vm.showContainerInfoDialog,"dialog-items":_vm.envsToShow(_vm.currentContainer)},on:{"close":function($event){_vm.showContainerInfoDialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Image: "+_vm._s(_vm.currentContainer.image.split('/')[1])+" ")]},proxy:true}],null,false,3716502127)}):_vm._e(),(_vm.showConfirmation)?_c('confirmation-dialog',{staticClass:"dialog",attrs:{"callbackMethod":_vm.callbackOnceConfirmed,"confirmationMsg":_vm.confirmationMsg,"show":_vm.showConfirmation},on:{"close":function($event){_vm.showConfirmation = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }