<template>
<v-container>
  <div v-if="environment" class="pa-0">
    <v-row align="start" no-gutters class="pb-2">
      <span class="text-h2">
        {{ envName }}
      </span>
      <v-spacer />
      <v-btn
        v-if="isGameServer"
        :to="{
          name: 'environment',
          params: { envName: environment.name, id: environment.id },
        }"
        class="mr-6"
        color="primary darken-2"
        :disabled="loading"
        large
        elevation="10"
      >
        {{ labels.gs }}
      </v-btn>
    </v-row>

    <v-row align="center" no-gutters>
      <v-btn-toggle
        v-model="selectedNamespaces"
        color="primary"
        mandatory
        multiple
        rounded
      >
        <v-btn
          v-for="(readOnly, namespace) in namespaces"
          :key="namespace"
          :value="namespace"
          x-small
        >
          {{ namespace }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer />
      <v-checkbox
          v-model="system"
          v-if="canViewSystem"
          color="primary"
          hide-details="auto"
          :label="labels.showSystem"
      />
    </v-row>
    <v-row no-gutters>
      <v-data-table
        v-if="!firstLoad"
        class="flex"
        :headers="headers"
        :item-class="(item) => highlightItem(item)"
        :items="visibleWorkloads"
        :items-per-page="itemsPerPage"
        multi-sort
        :search="search"
        :sort-by="sort"
        :sort-desc="desc"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            :disabled="loading"
            hide-details
            :label="labels.search"
            :prepend-icon="'$search'"
            single-line
          />
        </template>
        <template v-slot:item.metadata.namespace="{ value }">
          <v-chip dark color="primary">{{ value }}</v-chip>
        </template>
        <template v-slot:item.type="{ item, value }">
          <v-chip
            :color="getUnavailableReplicas(item) ? 'warning' : 'success'"
            dark
          >
            {{ value }}
          </v-chip>
        </template>
        <template v-slot:item.replicas="{ item }">
          {{ getReadyReplicas(item) }} / {{ getReplicas(item) }}
        </template>
        <template v-slot:item.version="{ item }">
          <span class="monospace">
            <!-- Take the last container in case we've a sidecar (e.g. linkerd-proxy) -->
          {{ getLast(item) }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            :disabled="!canRestart(item)"
            text outlined
            color="primary"
            @click="restart(item)"
          >
            {{ labels.restart }}
          </v-btn>
          <!-- <v-btn
            :disabled="!canScale(item)"
            text outlined
            color="primary"
            @click="scale(item)"
          >
            {{ labels.scale }}
          </v-btn> -->
          <v-btn
            text outlined
            color="primary"
            @click="showInfo(item)"
          >
            {{ labels.info }}
          </v-btn>
        </template>
      </v-data-table>
      <v-skeleton-loader
        v-else-if="loading"
        class="flex"
        loading
        type="table"
      />
    </v-row>
  </div>
  <environment-warning v-else-if="!loading" />

  <pod-details
    v-if="showInfoDialog"
    @close="showInfoDialog = false"
    @showDialog="showContainerDialog"
    :workload="currentWorkload"
    :show="showInfoDialog"
    class="dialog-1"
  />

  <details-dialog
    v-if="showContainerInfoDialog"
    @close="showContainerInfoDialog = false"
    :show="showContainerInfoDialog"
    :dialog-items="envsToShow(currentContainer)"
    class="dialog-2"
  >
    <template v-slot:title>
      Image: {{ currentContainer.image.split('/')[1] }}
    </template>
  </details-dialog>

  <confirmation-dialog
    v-if="showConfirmation"
    :callbackMethod="callbackOnceConfirmed"
    :confirmationMsg="confirmationMsg"
    @close="showConfirmation = false"
    :show="showConfirmation"
    class="dialog"
  />

</v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import {
  filter,
  get,
  isEmpty,
  last,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { getName, getNamespace } from '@/helpers';

export default {
  name: 'k8s',
  beforeDestroy() {
    this.clearInterval();
  },
  components: {
    confirmationDialog: () => import('@/components/confirmationDialog.vue'),
    detailsDialog: () => import('@/components/detailsDialog.vue'),
    environmentWarning: () => import('@/components/environmentWarning.vue'),
    podDetails: () => import('@/components/podDetails.vue'),
  },
  props: {
    envName: {
      type: String,
      default: '',
      required: true,
    },
    id: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  mounted() {
    this.setTimerMethod(this.fetchInitialData);
    this.fetchInitialData();
    if (this.refresh) this.setInterval();
  },
  data: () => ({
    callbackOnceConfirmed: () => {},
    confirmationMsg: null,
    currentContainer: null,
    currentWorkload: null,
    desc: [false, false],
    firstLoad: true,
    itemsPerPage: 50,
    labels,
    search: null,
    selectedNamespaces: [],
    showConfirmation: false,
    showContainerInfoDialog: false,
    showInfoDialog: false,
    sort: ['unavailableReplicas', 'metadata.name'],
    system: false, // show system nanespaces
  }),
  computed: {
    ...mapState('fetch', [
      'environment',
      'environments',
      'namespaces',
      'permissions',
      'workloads',
    ]),
    ...mapState('shared', [
      'loading',
      'refresh',
    ]),
    canViewCore() {
      return get(this.permissions, 'environment_view_core', false);
    },
    canViewSystem() {
      return get(this.permissions, 'admin_users', false);
    },
    environmentID() {
      // Check if we got the environment ID from the route params,
      // otherwise get it by name from `environments`
      if (!isEmpty(this.id)) return this.id;
      const id = get(
        this.environments.filter((e) => e.name === this.envName)[0],
        'id',
      );
      return id;
    },
    headers() {
      return [
        {
          filterable: true,
          text: this.labels.namespace,
          value: 'metadata.namespace',
        },
        {
          filterable: true,
          text: this.labels.name,
          value: 'metadata.name',
        },
        {
          filterable: false,
          text: this.labels.controller,
          value: 'type',
        },
        {
          filterable: false,
          text: this.labels.replicas,
          sortable: false,
          value: 'replicas',
        },
        {
          filterable: false,
          text: this.labels.version,
          sortable: false,
          value: 'version',
        },
        {
          filterable: false,
          text: this.labels.actions,
          sortable: false,
          value: 'actions',
        },
      ];
    },
    isGameServer() {
      return get(this.environment, 'is_game_server');
    },
    visibleWorkloads() {
      return filter(
        this.workloads,
        (workload) => this.selectedNamespaces.includes(
          get(workload, ['metadata', 'namespace']),
        ),
      );
    },
  },
  methods: {
    ...mapActions({
      clear: 'fetch/clear',
      clearInterval: 'fetch/clearInterval',
      fetchEnvironment: 'fetch/fetchEnvironment',
      fetchEnvironmentPermissions: 'fetch/fetchEnvironmentPermissions',
      fetchNamespaces: 'fetch/fetchNamespaces',
      fetchWorkloads: 'fetch/fetchWorkloads',
      manageWorkload: 'fetch/manageWorkload',
      setInterval: 'fetch/setInterval',
      setTimerMethod: 'fetch/setTimerMethod',
      toggleRefresh: 'shared/toggleRefresh',
    }),
    canRestart(workload) {
      const permissionsGranted = get(
        this.permissions,
        (
          getName(workload).startsWith('gls')
            ? 'k8s_restart_game'
            : 'k8s_restart_core'
        ),
      ) && (workload.type === 'deployment');
      const workloadNS = get(workload, 'metadata.namespace');
      return permissionsGranted && !get(this.namespaces, workloadNS, true);
    },
    // canScale(workload) {
    //   return get(
    //     this.permissions,
    //     (
    //       getName(workload).startsWith('gls')
    //       ? 'k8s_scale_game'
    //       : 'k8s_scale_core'
    //     ),
    //   );
    // },
    envsToShow(container) {
      const envs = container.env;
      return envs.filter((env) => this.showEnv(env.name));
    },
    fetchInitialData() {
      const promises = [
        this.fetchEnvironment(this.environmentID),
        this.fetchEnvironmentPermissions(this.environmentID),
        this.fetchNamespaces({ environmentID: this.environmentID }),
        this.fetchWorkloads(this.environmentID),
      ];
      Promise.all(promises).then(() => {
        const coreNamespace = get(
          this.environment,
          'core_namespace',
          'default',
        );
        this.selectedNamespaces = (
          Object.keys(this.namespaces).includes(coreNamespace)
            ? [coreNamespace]
            : []
        );
      }).catch((error) => {
        console.log(error);
        this.toggleRefresh(false);
      }).finally(() => {
        this.firstLoad = false;
      });
    },
    fetchVisibleData() {
      this.fetchWorkloads();
    },
    getLast(pod) {
      return last(pod.spec.template.spec.containers).image.split(':')[1];
    },
    getReadyReplicas(item) {
      return this.getReplicas(item) - this.getUnavailableReplicas(item);
    },
    getReplicas(item) {
      return get(
        item.status,
        item.type === 'daemonset' ? 'desiredNumberScheduled' : 'replicas',
        0,
      );
    },
    getUnavailableReplicas(item) {
      return get(
        item.status,
        item.type === 'daemonset' ? 'numberUnavailable' : 'unavailableReplicas',
        0,
      );
    },
    highlightItem(workload) {
      return (
        this.getUnavailableReplicas(workload)
          ? 'workload--restarting'
          : false
      );
    },
    restart(workload) {
      /* eslint no-param-reassign: ["off"] */
      this.confirmationMsg = (
        `Attention. ${this.labels.restartWarning}: ${getName(workload)}`
      );
      this.callbackOnceConfirmed = () => {
        this.showConfirmation = false;
        const body = {
          name: getName(workload),
          namespace: getNamespace(workload),
          workload_type: workload.type,
        };
        this.manageWorkload({
          environmentID: this.environmentID,
          body,
          method: 'PUT',
        }).then(() => {
          // Fake local object until we get an update from REST-API
          // First, find the index of `workload` in `this.workloads`
          const idx = this.workloads.findIndex((w) => (
            w.metadata.selfLink === workload.metadata.selfLink
          ));
          if (workload.type === 'daemonset') {
            this.workloads[idx].status.numberUnavailable = 1;
          } else {
            this.workloads[idx].status.unavailableReplicas = 1;
          }
        });
        if (this.refresh) {
          this.clearInterval();
          this.setInterval();
        }
      };
      this.showConfirmation = true;
    },
    showEnv(envVar) {
      return !envVar.startsWith('_');
    },
    showInfo(workload) {
      this.showInfoDialog = true;
      this.currentWorkload = workload;
    },
    showContainerDialog(container) {
      this.currentContainer = container;
      this.showContainerInfoDialog = true;
    },
  },
  watch: {
    system() {
      this.fetchNamespaces({
        environmentID: this.environmentID,
        system: this.system,
      });
    },
  },
};
</script>

<style>
  .v-divider {
    border-width: 2px;
  }
  .v-item-group.v-btn-toggle {
    display: contents;
  }
  .dialog-1 {
    z-index: 9900;
  }
  .dialog-2 {
    z-index: 9999;
  }
  .monospace {
    font-family: monospace;
  }
  .workload--restarting {
    animation: restartcolorchange 1s linear infinite alternate;
  }
  @keyframes restartcolorchange {
    from {background-color: var(--v-warning-darken1);}
    to {background-color: transparent;}
  }
</style>
